import { createElement, useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import styled, { css } from 'styled-components'
import Webcam from 'react-webcam'
import { TwitterPicker } from 'react-color'
import {
  createColorLayer,
  createUrlLayer,
  createWebcamLayer,
  transformProps,
  layerTypes
} from './libraries/layer'
import * as c from './constants'

import { blendModes, objectFit } from './constants/css'

const { localStorage } = window

const DEFAULT_LAYERS = [
  createColorLayer('red')
]

const Jail = styled.div`
  flex: 1;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 0.25rem;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  overflow: hidden;
  & > * {
    height: 100%;
  }
`

const PreviewBox = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  height: 32px;
`

const LayerTitle = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: gray;
  font-size: 0.75rem;
`

const ToggleSidebar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: black;
  color: white;
  opacity: ${props => props.show ? '0.25' : '0'};
  cursor: pointer;
  padding: 0.25rem 1rem;
  transition: opacity 2s ease-out;
  :hover {
    opacity: 1;
  }
`

const AppWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex:1;
  & > * {
    margin: 0 3px;
  }
`

const SmallInputWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 32px;
  align-items: center;
  position: relative;
  &::before {
    content: "${props => props.title}";
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 3px;
    color: darkgray;
    position: absolute;
  }
`

const InfoLabel = styled.p`
  font-size: 0.75rem;
  color: gray;
  display: flex;
  align-items: center;
`

const SmallInputElem = styled.input`
  border: none;
  appearance: none;
  width: 0;
  border-radius: 6px;
  padding: 0.1rem;
  flex: 1;
  outline: none;
  height: 100%;
  padding-left: 1rem;
`

const SmallInput = ({ title, ...props }) => {
  return (
    <SmallInputWrap title={title}>
      <SmallInputElem {...props} />
    </SmallInputWrap>
  )
}

const PreviewWrapper = styled.main`
  flex: 1;
  position: relative;
  overflow: hidden;
`

const LayerContent = styled.figure`
  ${props => !props.isPreview
  ? css`
    position: absolute;
    width: 100%;
    height: 100%;
    & > * {
      width: 100%;
      height: 100%;
      object-fit: ${props => props.objectFit || 'contain'};
    }
  `
  : css`
    display: flex;
    flex: 1;
  `}
  margin: 0;
  background-color: ${props => props.color};
  & > * {
    object-fit: ${props => props.objectFit || 'contain'};
  }
`

const LayerWrapper = styled.div`
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 0.5rem;
  }
`

const Toolbar = styled.aside`
  width: 256px;
  display:${props => !props.show ? 'none' : 'initial'};
  padding: 1rem;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: white;
  z-index: 10;
`

function App () {
  const [layers, setLayers] = useState(DEFAULT_LAYERS)
  const [layerType, setLayerType] = useState(c.TYPE_WEBCAM)
  const [layerUrl, setLayerUrl] = useState()
  const [showToolbar, setShowToolbar] = useState(true)
  const [preset, setPreset] = useState()
  const [presets, setPresets] = useState([])
  const [saveName, setSaveName] = useState()

  const [mediaDevices, setMediaDevices] = useState([])
  const [mdId, setMdId] = useState()

  const [color, setColor] = useState()

  const checkUrlAndCreateLayer = async (url) => {
    try {
      const preflight = await axios.get(url)
      const type = preflight.headers['content-type']
      return createUrlLayer(url, getElementProps(type))
    } catch (e) {
      return createUrlLayer(url, getElementProps())
    }
  }
  const getElementProps = (contentType) => {
    if (!contentType) return { tag: 'img' }
    const [content, type] = contentType.split('/')
    if (content === 'image') return { tag: 'img' }
    if (content === 'video') {
      return {
        tag: 'video',
        domProps: {
          autoplay: '',
          muted: '',
          loop: true
        }
      }
    }
    return { tag: 'iframe' }
  }

  const updateLayerStyle = (layer, key, value) => ({
    ...layer,
    style: {
      ...layer.style,
      [key]: value
    }
  })

  const addLayer = (layer) => {
    setLayers(layers => [...layers, layer])
  }

  const updateLayer = (id, cb) => {
    setLayers(layers => layers.map((layer) => id === layer.id
      ? cb(layer)
      : layer))
  }

  const handleLayerOpacityChange = (id) => (e) => {
    setLayers(layers => layers.map((layer) => id === layer.id
      ? updateLayerStyle(layer, 'opacity', e.target.value / 100)
      : layer))
  }
  const handleLayerUrlChange = (e) => setLayerUrl(e.target.value)
  const handleAddUrlLayer = () => {
    checkUrlAndCreateLayer(layerUrl)
      .then(addLayer)
  }

  const handleAddWebcamLayer = () => {
    const wcLayer = createWebcamLayer(mdId)
    addLayer(wcLayer)
  }
  const handleAddColorLayer = () => {
    const colorLayer = createColorLayer(color)
    addLayer(colorLayer)
  }

  const handleLayerBlendModeChange = (id) => (e) => {
    const value = e.target.value
    updateLayer(id, layer => updateLayerStyle(layer, 'mix-blend-mode', value))
  }

  const handleLayerFitChange = (id) => (e) => {
    const value = e.target.value
    updateLayer(id, layer => ({ ...layer, objectFit: value }))
  }
  const handleLayerTypeChange = (event) => {
    const { value } = event.target
    setLayerType(value)
  }
  const handleMediaDeviceChange = (e) => {
    setMdId(e.target.value)
  }
  const handleTransformChange = (layerId, transformId) =>
    ({ target }) => {
      setLayers(layers => layers.map((layer) => layer.id === layerId
        ? { ...layer, transform: { ...layer.transform, [transformId]: target.value } }
        : layer
      ))
    }
  const handleColorChange = (colorObj) => {
    setColor(colorObj.hex)
  }
  const renderAddLayer = (layerType) => {
    if (layerType === c.TYPE_URL) {
      return (
        <>
          <input value={layerUrl} onChange={handleLayerUrlChange} />
          <button onClick={handleAddUrlLayer}>Add to layer</button>
        </>
      )
    }
    if (layerType === c.TYPE_WEBCAM) {
      return (
        <>
          <select value={mdId} onChange={handleMediaDeviceChange}>
            {mediaDevices.map((md, i) => <option key={i} value={md.deviceId}>{md.label}</option>)}
          </select>
          <button onClick={handleAddWebcamLayer}>Add webcam layer</button>
        </>
      )
    }
    if (layerType === c.TYPE_COLOR) {
      return (
        <>
          <TwitterPicker color={color} onChangeComplete={handleColorChange} />
          <button onClick={handleAddColorLayer}>Add color layer</button>
        </>
      )
    }
    return <p>Not implemented yet</p>
  }
  const getStyleFromLayer = (layer, blacklist = []) => {
    const entries = Object.entries(layer.style)
    return entries.reduce((acc, cur) => {
      const [key, value] = cur
      if (blacklist.indexOf(key) > -1) return acc
      return {
        ...acc,
        [key]: value
      }
    }, {})
  }
  const getTransformFromLayer = (layer, blacklist = []) => {
    const entries = Object.entries(layer.transform)
    return entries.reduce((acc, cur) => {
      const [key, value] = cur
      if (blacklist.indexOf(key) > -1) return acc
      const propInfo = transformProps.find((group) => group.props.indexOf(key) > -1)
      const { unit = '' } = propInfo || {}
      return `${acc} ${key}(${value}${unit})`
    }, '')
  }
  const getPropsFromLayer = (layer, opts = {}) => {
    const { isPreview = false } = opts
    const blacklistTransforms = isPreview
      ? ['translateX', 'translateY', 'translateZ']
      : []
    const blacklistStyle = isPreview
      ? ['mix-blend-mode']
      : []
    return {
      src: layer.url,
      style: {
        ...getStyleFromLayer(layer, blacklistStyle),
        transform: getTransformFromLayer(layer, blacklistTransforms)
      },
      ...layer.domProps
    }
  }
  const renderLayer = (layer, id, opts = {}) => {
    const {
      keyAddon = '',
      isPreview = false
    } = opts
    const layerProps = {
      key: id + keyAddon,
      objectFit: layer.objectFit,
      isPreview
    }
    if (layer.type === c.TYPE_WEBCAM) {
      return (
        <LayerContent {...layerProps}>
          <Webcam
            width='100%'
            videoConstraints={{ deviceId: layer.deviceId }}
            {...getPropsFromLayer(layer, opts)}
          />
        </LayerContent>
      )
    }
    if (layer.type === c.TYPE_COLOR) {
      return (
        <LayerContent {...layerProps} color={layer.color} {...getPropsFromLayer(layer, opts)} />
      )
    }
    return (
      <LayerContent {...layerProps}>
        {createElement(layer.tag, getPropsFromLayer(layer, opts))}
      </LayerContent>
    )
  }
  const handleToggleToolbar = () => {
    setShowToolbar(show => !show)
  }
  const renderLayerSpecificSettings = (layer, id) => {
    const title = (layer.type === c.TYPE_URL && layer.url) ||
      layer.type
    return (
      <PreviewBox>
        <LayerTitle title={title}>{title}</LayerTitle>
        <Jail>
          {renderLayer(layer, id, { isPreview: true, keyAddon: 'preview' })}
        </Jail>
      </PreviewBox>
    )
  }
  const renderLayerPreview = (layer) => (
    <LayerWrapper key={layer.id}>
      {renderLayerSpecificSettings(layer)}
      <InputGroup>
        <InfoLabel>Scale</InfoLabel>
        <SmallInput title='x' type='number' step='0.1' onChange={handleTransformChange(layer.id, 'scaleX')} value={layer.transform.scaleX} />
        <SmallInput title='y' type='number' step='0.1' onChange={handleTransformChange(layer.id, 'scaleY')} value={layer.transform.scaleY} />
        <SmallInput title='z' type='number' step='0.1' onChange={handleTransformChange(layer.id, 'scaleZ')} value={layer.transform.scaleZ} />
      </InputGroup>
      <InputGroup>
        <InfoLabel>Rotate</InfoLabel>
        <SmallInput title='x' type='number' step='10' onChange={handleTransformChange(layer.id, 'rotateX')} value={layer.transform.rotateX} />
        <SmallInput title='y' type='number' step='10' onChange={handleTransformChange(layer.id, 'rotateY')} value={layer.transform.rotateY} />
        <SmallInput title='z' type='number' step='10' onChange={handleTransformChange(layer.id, 'rotateZ')} value={layer.transform.rotateZ} />
      </InputGroup>
      <InputGroup>
        <InfoLabel>Move</InfoLabel>
        <SmallInput title='x' type='number' step='100' onChange={handleTransformChange(layer.id, 'translateX')} value={layer.transform.translateX} />
        <SmallInput title='y' type='number' step='100' onChange={handleTransformChange(layer.id, 'translateY')} value={layer.transform.translateY} />
        <SmallInput title='z' type='number' step='100' onChange={handleTransformChange(layer.id, 'translateZ')} value={layer.transform.translateZ} />
      </InputGroup>
      <InputGroup>
        <InfoLabel>Opacity</InfoLabel>
        <input type='range' onChange={handleLayerOpacityChange(layer.id)} />
      </InputGroup>
      <InputGroup>
        <InfoLabel>Blend</InfoLabel>
        <select onChange={handleLayerBlendModeChange(layer.id)} value={layer.style['mix-blend-mode']}>
          {blendModes.map((bm) => <option value={bm} key={bm}>{bm}</option>)}
        </select>
      </InputGroup>
      <InputGroup>
        <InfoLabel>Fit</InfoLabel>
        <select onChange={handleLayerFitChange(layer.id)} value={layer.objectFit}>
          {objectFit.map((objfit) => <option value={objfit} key={objfit}>{objfit}</option>)}
        </select>
      </InputGroup>
    </LayerWrapper>
  )

  const handleDevices = useCallback(mediaDevices =>
    setMediaDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
  [setMediaDevices]
  )

  const loadPreset = (presetName) => {
    const jsonStr = localStorage.getItem('saves')
    if (!jsonStr) return
    const json = JSON.parse(jsonStr)
    const preset = json[presetName]
    setLayers(preset)
  }
  const savePreset = (presetName, layers) => {
    const presets = getPresets()
    const newPresets = {
      ...presets,
      [presetName]: layers
    }
    const newPresetsJson = JSON.stringify(newPresets)
    localStorage.setItem('saves', newPresetsJson)
  }
  const getPresets = () => {
    const jsonStr = localStorage.getItem('saves')
    const presets = JSON.parse(jsonStr)
    return presets
  }

  const handlePresetChange = ({ target }) => {
    const presetName = target.value
    setPreset(presetName)
  }
  const handleSaveNameChange = ({ target }) => {
    const name = target.value
    setSaveName(name)
  }
  const handleSaveClick = () => {
    savePreset(saveName, layers)
  }
  const handleLoadClick = () => {
    setPreset(preset)
  }
  useEffect(() => {
    if (!preset) return
    loadPreset(preset)
  }, preset)
  useEffect(() => {
    const storedPresets = getPresets()
    if (!storedPresets) return
    const presetsArr = Object.entries(storedPresets).map(
      (cur) => ({
        name: cur[0],
        layers: cur[1]
      }))
    setPresets(presetsArr)
  }, [])
  useEffect(() => {
    navigator.mediaDevices?.enumerateDevices().then(handleDevices)
  }, [handleDevices])
  return (
    <AppWrapper>
      <PreviewWrapper>
        {layers.map(renderLayer)}
      </PreviewWrapper>
      <ToggleSidebar onClick={handleToggleToolbar} show={showToolbar}>
        <p>{showToolbar ? 'Hide' : 'Show'}</p>
      </ToggleSidebar>
      <Toolbar show={showToolbar}>
        <select onChange={handlePresetChange}>
          <option>Presets..</option>
          {presets.map((preset, i) => <option key={i}>{preset.name}</option>)}
        </select>
        <button onClick={handleLoadClick}>Load</button>
        <input onChange={handleSaveNameChange} placeholder='name' />
        <button onClick={handleSaveClick}>Save</button>
        <p>Layers</p>
        <div>
          <p>Add layer</p>
          <select onChange={handleLayerTypeChange} value={layerType}>
            {layerTypes.map((lt, i) =>
              <option key={i} value={lt.value}>{lt.displayName}</option>
            )}
          </select>
          {renderAddLayer(layerType)}
        </div>
        {layers.map(renderLayerPreview)}
      </Toolbar>
    </AppWrapper>
  )
}

export default App
