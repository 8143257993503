import * as c from '../constants'

export const layerTypes = [
  { value: c.TYPE_URL, displayName: 'URL' },
  { value: c.TYPE_WEBCAM, displayName: 'Webcam' },
  { value: c.TYPE_COLOR, displayName: 'Color' }
]

export const transformProps = [
  {
    props: ['rotateX', 'rotateY', 'rotateZ'],
    unit: 'deg'
  },
  {
    props: ['translateX', 'translateY', 'translateZ'],
    unit: 'px'
  }

]

export const createLayer = (opts = {}) => ({
  id: parseInt(Math.random() * 100000),
  tag: 'div',
  objectFit: 'contain',
  style: {
    opacity: 0.5,
    'mix-blend-mode': 'normal'
  },
  transform: {
    scaleX: 1.0,
    scaleY: 1.0,
    scaleZ: 1.0,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    translateY: 0,
    translateX: 0,
    translateZ: 0
  },
  ...opts
})

export const createUrlLayer = (url, opts) => {
  const layer = {
    url,
    type: c.TYPE_URL,
    ...createLayer(opts)
  }
  return layer
}
export const createWebcamLayer = (deviceId, opts) => {
  const layer = {
    type: c.TYPE_WEBCAM,
    deviceId,
    ...createLayer(opts)
  }
  return layer
}
export const createColorLayer = (color, opts) => {
  return {
    ...createLayer(opts),
    type: c.TYPE_COLOR,
    color
  }
}
